<template>
  <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }"
    class="align-self-start col-12 pa-0">
    <v-form @submit.prevent="passes(save)">
      <v-container>
          <v-row>
            <v-col xs12 ḿd6>
              <v-checkbox
                v-model="values.crearPedidoPorDetalle"
                label="Crear Pedido por Detalle"
                color="primary"
                @change="saveChekboxValues('crearPedidoPorDetalle', values.crearPedidoPorDetalle)"
                true-value="1" 
                false-value="0"
                hide-details
                :disabled="crearPedidoPorDetalleDisabled"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 ḿd6>
              <v-checkbox
                  v-model="values.crearPedidoPorArticulo"
                  label="Crear Pedido por Artículo"
                  color="primary"
                  @change="saveChekboxValues('crearPedidoPorArticulo', values.crearPedidoPorArticulo)"
                  true-value="1" 
                  false-value="0"
                  hide-details
                  :disabled="crearPedidoPorArticuloDisabled"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col xs12 ḿd6>
              <ValidationProvider name="Contraseña" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="values.password"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-1"
                  label="Contraseña"
                  counter
                  @click:append="showPassword = !showPassword"
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <div align-self-center class="mt-4">
            <v-btn type="submit" @click.prevent="passes(save)" color="primary"
                dark :loading="saving" :disabled="saving">Guardar</v-btn>
          </div>
      </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

const {SHA256} = require("sha2");

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    loading: false,
    saving: false,
    showPassword: false,
    crearPedidoPorDetalleDisabled:false,
    crearPedidoPorArticuloDisabled:false,
    llave: '',
    valor: '',
    values: {
      crearPedidoPorDetalle: 0,
      crearPedidoPorArticulo: 0,
      password: '',
    },
  }),

  methods: {
    queryConfiguration: function () {
      this.loading = true

      this.$soapClient.call('listarDefault', {
        key: null,
      })
      .then((response) => {
        this.setValues(response.data.resultado)
      })
      .catch((error) => {
        if (!!error.code && (error.code === "400")) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },
    setValues: function (values) {
      let valuesData = {}

      for (var i = 0; i < values.length; i++) {
        valuesData[ values[i].llave ] = values[i].valor
      }

      this.values = valuesData
    },
    saveChekboxValues: function (llave, valor) {
      this.llave = llave
      this.valor = valor

      if (llave == "crearPedidoPorDetalle") {
        this.crearPedidoPorArticuloDisabled = true
      } else {
        this.crearPedidoPorDetalleDisabled = true
      }
    },
    save: function () {
      this.loading = true

      this.$soapClient.call('grabarActualizarDefaultString', {
        key: this.llave,
        value: this.valor,
        password: Array.from(SHA256(this.values.password)).toString("US-ASCII")
      })
      .then(() => {
        this.crearPedidoPorDetalleDisabled = false
        this.crearPedidoPorArticuloDisabled = false

        this.$eventHub.$emit('snackbar-message', 'Los datos fueron guardados con éxito', 'success')
      })
      .catch((error) => {
        if ((!!error.code && (error.code === "400")) || (error.code === '401')) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },
  },

  created: function () {
    this.queryConfiguration()
  }
}

</script>
